module.exports = {
  defaultTitle: 'Agencija za Hemikalije',
  logo: '',
  author: 'Agencija za Hemikalije',
  url: 'agencijazahemikalije.com',
  legalName: 'Agencija za Hemikalije',
  defaultDescription:
    'Agencija za Hemikalije - Eksperti, savetnici u upravljanju hemikalijama i biocidima u Republici Srbiji.',
  socialLinks: {
    twitter: '',
    github: '',
    linkedin: '',
    instagram: '',
    youtube: '',
  },
  googleAnalyticsID: 'UA-37444935-1',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@',
  },
  address: {
    city: 'Beograd',
    region: 'Beograd',
    country: 'Srbija',
    zipCode: '11000',
  },
  contact: {
    email: 'agencijazahemikalije@gmail.com',
    phone: '+381 (66) 630 6782',
  },
  foundingDate: '2010',
};
